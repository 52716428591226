import React from "react"
import Page from "../components/page/Page"
import { graphql } from "gatsby"
import "./libros.css"

export default props => {
  const librosEdges = props.data.allContentfulLibros.edges
  return (
    <Page class="libros" slug="libros" title="Libros">
      <div className="list">
        <header className="list-header">
          <h1 className="title">Libros</h1>
        </header>
        <main className="list-main">
          {librosEdges.map((edge, i) => {
            return (
              <div className="item" key={i}>
                <h2 className="item-title">{edge.node.titulo}</h2>
                <div className="item-credits">
                  {edge.node.creditos.creditos}
                </div>
                <div className="item-publisher">{edge.node.editorial}</div>
                <div className="item-place-year">
                  {edge.node.lugar}, {edge.node.anho}
                </div>
                {edge.node.portada && (
                  <img
                    className="item-img"
                    src={edge.node.portada ? edge.node.portada.file.url : null}
                    alt="portada"
                  />
                )}
                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html: edge.node.descripcion.childMarkdownRemark.html,
                  }}
                />
                <a
                  className="item-download"
                  href={edge.node.pdf ? edge.node.pdf.file.url : null}
                >
                  <i className="material-icons">get_app</i>
                  <span>descargar</span>
                </a>
              </div>
            )
          })}
        </main>
      </div>
    </Page>
  )
}

export const librosQuery = graphql`
  query {
    allContentfulLibros(sort: { fields: anho, order: DESC }) {
      edges {
        node {
          titulo
          portada {
            file {
              url
            }
          }
          creditos {
            creditos
          }
          editorial
          anho
          lugar
          descripcion {
            childMarkdownRemark {
              html
            }
          }
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`
